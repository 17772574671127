import React from "react";
import { CardContent, Grid } from "@mui/material";

import FeaturedRow from "./itemcomponents/FeaturedRow";
import FeaturedChip from "./itemcomponents/FeaturedChip";
import FeaturedImage from "./itemcomponents/FeaturedImage";
import ItemImage from "./itemcomponents/ItemImage";
import ItemTitle from "./itemcomponents/ItemTitle";
import ItemRating from "./itemcomponents/ItemRating";
import ItemPrice from "./itemcomponents/ItemPrice";
import ItemDescription from "./itemcomponents/ItemDescription";
import ItemDetails from "./itemcomponents/ItemDetails";

import VideoModal from "./VideoModal";

import nonveg from "../assets/utils/nonveg.svg";
import veg from "../assets/utils/veg.svg";
import youtube from "../assets/utils/youtube.svg";

const AccordionItem = ({ data }) => {
  const [toggleDetails, setToggleDetails] = React.useState(false);
  const [toggleVideo, setToggleVideo] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);
  const [selectedID, setSelectedID] = React.useState(null);

  const toggleDrawer = (id, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSelectedID(id);

    setToggleDetails(open);
  };

  const toggleVideoURL = (url) => {
    setVideoURL(url);
    setToggleVideo(true);
  };

  const Row = ({ index, style }) => (
    <div style={style}>
      <CardContent key={index} style={{ overflow: "hidden" }}>
        <Grid container minHeight={160} spacing={2}>
          <Grid
            item
            xs={6}
            md={2}
            onClick={
              data[index].is_available ? toggleDrawer(data[index].id, true) : ""
            }
          >
            <ItemImage
              image={data[index].image}
              status={data[index].is_available}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container rowSpacing={0.5}>
              <Grid
                item
                xs={12}
                onClick={
                  data[index].is_available
                    ? toggleDrawer(data[index].id, true)
                    : ""
                }
              >
                <Grid container spacing={2}>
                  <FeaturedRow
                    topper={data[index].is_chart_topper}
                    image={data[index].food_type === "non-veg" ? nonveg : veg}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                onClick={
                  data[index].is_available
                    ? toggleDrawer(data[index].id, true)
                    : ""
                }
              >
                <ItemTitle title={data[index].name} />
              </Grid>
              <Grid
                item
                xs={12}
                onClick={
                  data[index].is_available
                    ? toggleDrawer(data[index].id, true)
                    : ""
                }
              >
                <Grid container>
                  <ItemRating
                    rating={data[index].rating || null}
                    review_count={data[index].rating_count}
                  />
                </Grid>
              </Grid>
              {data[index].is_available ? (
                <Grid item xs={12} onClick={toggleDrawer(data[index].id, true)}>
                  <ItemPrice price={data[index].price} />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <FeaturedChip label="Not Available" unavailable={true} />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                onClick={
                  data[index].is_available
                    ? toggleDrawer(data[index].id, true)
                    : ""
                }
              >
                <ItemDescription
                  description={data[index].description.substring(
                    0,
                    data[index].yt_video ? 12 : 60
                  )}
                  trim
                />
              </Grid>
              {data[index].yt_video && (
                <Grid
                  item
                  xs={12}
                  mt={1}
                  onClick={
                    data[index].is_available
                      ? () => toggleVideoURL(data[index].yt_video)
                      : ""
                  }
                >
                  <FeaturedImage image={youtube} url={data[index].yt_video} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );

  return (
    <>
      {data.map((item, idx) => {
        return (
          <CardContent key={idx}>
            <Grid container minHeight={160} spacing={2}>
              <Grid
                item
                xs={5}
                md={2}
                onClick={item.is_available ? toggleDrawer(item.id, true) : ""}
              >
                <ItemImage image={item.image} status={item.is_available} />
              </Grid>
              <Grid item xs={6}>
                <Grid container rowSpacing={0.5}>
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item.id, true) : ""
                    }
                  >
                    <Grid container spacing={2}>
                      <FeaturedRow
                        topper={item.is_chart_topper}
                        image={item.food_type === "non-veg" ? nonveg : veg}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item.id, true) : ""
                    }
                  >
                    <ItemTitle title={item.name} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item.id, true) : ""
                    }
                  >
                    <Grid container>
                      <ItemRating
                        rating={item.rating || null}
                        review_count={item.rating_count}
                      />
                    </Grid>
                  </Grid>
                  {item.is_available ? (
                    <Grid item xs={12} onClick={toggleDrawer(item.id, true)}>
                      <ItemPrice price={item.price} />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <FeaturedChip label="Not Available" unavailable={true} />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item.id, true) : ""
                    }
                  >
                    <ItemDescription
                      description={item.description.substring(
                        0,
                        item.yt_video ? 12 : 60
                      )}
                      trim
                    />
                  </Grid>
                  {item.yt_video && (
                    <Grid
                      item
                      xs={12}
                      mt={1}
                      onClick={
                        item.is_available
                          ? () => toggleVideoURL(item.yt_video)
                          : ""
                      }
                    >
                      <FeaturedImage image={youtube} url={item.yt_video} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        );
      })}
      {/* <List
        height={180 * data.length}
        width={"100%"}
        style={{ overflow: "hidden" }}
        itemCount={data.length}
        itemSize={175}
      >
        {Row}
      </List> */}
      {data.length === 0 && (
        <CardContent>
          <Grid container minHeight={160} spacing={2}>
            <Grid item xs={12}>
              <ItemDescription description="No items found" />
            </Grid>
          </Grid>
        </CardContent>
      )}
      <ItemDetails
        selectedID={selectedID}
        state={toggleDetails}
        toggleDrawer={toggleDrawer}
        selectedData={data.find((item) => item.id === selectedID) || []}
        utilImages={{ nonveg, veg }}
      />
      <VideoModal open={toggleVideo} setOpen={setToggleVideo} url={videoURL} />
    </>
  );
};

export default AccordionItem;
