import * as React from "react";
import {
  Grid,
  SwipeableDrawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ForumIcon from "@mui/icons-material/Forum";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import StyledInput from "./controls/TextField";
import StyledTextArea from "./controls/StyledTextArea";
import StyledSubmit from "./controls/StyledSubmit";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useMutation } from "react-query";

import postFeedback from "../api/postFeedback";

import facebook from "../assets/logo/facebook.png";
import instagram from "../assets/logo/instagram.png";
import whatsapp from "../assets/logo/whatsapp.png";
import phone from "../assets/logo/phone.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AboutUs({ state, toggleDrawer, resturentInfo }) {
  const [toggleFeedback, setToggleFeedback] = React.useState(false);

  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [nameErr, setNameErr] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    mutate: postFeedbackMutation,
    isSuccess: postFeedbackSuccess,
    isError: postFeedbackError,
    error: postFeedbackErrorMessage,
  } = useMutation(["postFeedback"], ({ payload }) => {
    return postFeedback({ payload });
  });

  const handleName = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const handleMobile = (event) => {
    event.preventDefault();
    setMobile(event.target.value);
  };

  const handleMessage = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "") {
      setNameErr("Name is required");
    } else if (mobile === "") {
      setMobileErr("Mobile is required");
    } else if (mobile.length > 12 || mobile.length < 10) {
      setMobileErr("Mobile number must be 10-12 digits");
    } else if (message === "") {
      setMessageErr("Message is required");
    } else {
      setNameErr("");
      setMobileErr("");
      setMessageErr("");
      let payload = {
        name,
        mobile,
        message,
      };
      postFeedbackMutation({ payload });
    }
  };

  React.useEffect(() => {
    if (postFeedbackSuccess) {
      setName("");
      setMobile("");
      setMessage("");
      handleClick();
      setToggleFeedback(false);
    } else if (postFeedbackError) {
      console.log(postFeedbackErrorMessage);
      handleClickError();
    }
  }, [postFeedbackSuccess, postFeedbackError, postFeedbackErrorMessage]);

  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const list = () => (
    <Box
      sx={{ overflow: "hidden", width: "100%" }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ marginBottom: 5 }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Feedback added successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        sx={{ marginBottom: 5 }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {postFeedbackErrorMessage?.response?.data?.message}
        </Alert>
      </Snackbar>
      <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "start" }}>
          <IconButton aria-label="back-to-menu" onClick={toggleDrawer(false)}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "start", marginTop: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 18, marginLeft: 1 }}>
            About Us
          </Typography>
        </Grid>
      </Grid>
      <Grid container minHeight={100} spacing={0} marginX={3}>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "start",
            marginTop: 1,
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
            {resturentInfo?.result?.address?.length > 0 ? (
              <a
                href={resturentInfo?.result?.google_map}
                target="_blank"
                style={{ textDecoration: "none", color: "#5970E4" }}
              >
                {resturentInfo?.result?.address}
              </a>
            ) : (
              "No address found"
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
        <Grid
          item
          xs={2}
          sx={{ display: "flex", alignItems: "start", mt: 1.5 }}
        >
          <IconButton aria-label="back-to-menu">
            <AccessTimeIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            alignItems: "start",
            marginTop: 1,
            marginLeft: 1,
          }}
        >
          {resturentInfo?.result?.working_days?.length > 0 ? (
            <List sx={{ width: "100%" }}>
              {resturentInfo?.result?.working_days?.map((_, idx) => {
                return (
                  <div key={idx}>
                    {!_.is_holiday && (
                      <ListItem
                        disablePadding
                        secondaryAction={
                          <ListItemText
                            edge="end"
                            primary={
                              <Typography
                                sx={{ fontWeight: 500, fontSize: 13 }}
                              >
                                {tConvert(_.start_time.slice(0, 5)) +
                                  "    -    " +
                                  tConvert(_.end_time.slice(0, 5))}
                              </Typography>
                            }
                          />
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 500, fontSize: 13 }}>
                              {_.day.charAt(0).toUpperCase() + _.day.slice(1)}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </div>
                );
              })}
            </List>
          ) : (
            "No schedule found"
          )}
        </Grid>
      </Grid>
      <Grid
        container
        minHeight={50}
        spacing={0}
        marginTop={2}
        marginX={2}
        onClick={() => setToggleFeedback(true)}
      >
        <Grid
          item
          xs={2}
          sx={{ display: "flex", alignItems: "start", mt: 1.5 }}
        >
          <IconButton aria-label="back-to-menu">
            <ForumIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            alignItems: "start",
            marginTop: 2.5,
            marginLeft: 1,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
            Feedback or Suggestions
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "start",
            marginTop: 2.5,
          }}
        >
          <ChevronRightIcon />
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={5}>
        <Grid item xs={3}>
          {resturentInfo?.result?.mobile?.length > 0 ? (
            <a href={`tel:+${resturentInfo?.result?.mobile}`}>
              <img
                src={phone}
                style={{ width: "50px", height: "50px" }}
                alt="phone"
              />
            </a>
          ) : (
            <img
              src={phone}
              style={{ width: "50px", height: "50px" }}
              alt="phone"
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {resturentInfo?.result?.whatsapp_number?.length > 0 ? (
            <a href={`https://wa.me/${resturentInfo?.result?.whatsapp_number}`}>
              <img
                src={whatsapp}
                style={{ width: "50px", height: "50px" }}
                alt="whatsapp"
              />
            </a>
          ) : (
            <img
              src={whatsapp}
              style={{ width: "50px", height: "50px" }}
              alt="whatsapp"
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <a
            href={
              resturentInfo?.result?.facebook?.length > 0
                ? resturentInfo?.result?.facebook
                : "https://facebook.com"
            }
          >
            <img
              src={facebook}
              style={{ width: "50px", height: "50px" }}
              alt="facebook"
            />
          </a>
        </Grid>
        <Grid item xs={3}>
          <a
            href={
              resturentInfo?.result?.instagram?.length > 0
                ? resturentInfo?.result?.instagram
                : "https://instagram.com"
            }
          >
            <img
              src={instagram}
              style={{ width: "50px", height: "50px" }}
              alt="instagram"
            />
          </a>
        </Grid>
      </Grid>
    </Box>
  );

  const feedback = () => (
    <Box sx={{ overflow: "hidden", width: "100%" }} role="presentation">
      <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "start" }}>
          <IconButton
            aria-label="back-to-menu"
            onClick={() => setToggleFeedback(false)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "start", marginTop: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 18, marginLeft: 1 }}>
            Feedback
          </Typography>
        </Grid>
      </Grid>
      <Grid container minHeight={100} rowSpacing={3} marginX={2} marginTop={1}>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledInput
            label="Name"
            value={name}
            onChange={handleName}
            error={nameErr}
            setErr={setNameErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledInput
            label="Mobile Number"
            value={mobile}
            error={mobileErr}
            type="number"
            onChange={handleMobile}
            setErr={setMobileErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledTextArea
            label="Type your suggestions here"
            value={message}
            error={messageErr}
            onChange={handleMessage}
            setErr={setMessageErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledSubmit onClick={handleSubmit}>Submit</StyledSubmit>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "white",
            width: "100%",
          },
        }}
      >
        {!toggleFeedback && list()}
        {toggleFeedback && feedback()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
