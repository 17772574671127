import { Grid, Toolbar, AppBar } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

import logoblack from "../../assets/logo/logo.png";

import fetchResturentInfo from "../../api/fetchResturentInfo";

import { useQuery } from "react-query";

const CustomChip = styled(Chip)({
  [`&.${chipClasses.filled}`]: {
    backgroundColor: "black",
  },
});

const Index = (props) => {
  const { data: resturentInfo } = useQuery(["resturent-info"], () =>
    fetchResturentInfo()
  );

  return (
    // <HideOnScroll {...props}>
    <AppBar sx={{ boxShadow: "none", position: "relative" }}>
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingInline: 2, paddingBlock: 2 }}
        >
          <Grid xs={6} xl={6} item>
            {resturentInfo?.result?.photo?.length > 0 && (
              <img
                src={
                  resturentInfo?.result?.photo?.length > 0
                    ? resturentInfo?.result?.photo
                    : ""
                }
                height={
                  resturentInfo?.result?.photo?.length > 0 ? "90px" : "50px"
                }
                objectFit="fill"
                loading="lazy"
                width={
                  resturentInfo?.result?.photo?.length > 0 ? "90px" : "70px"
                }
                alt="logo"
              />
            )}
          </Grid>
          <Grid
            xs={6}
            xl={6}
            item
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems="center"
            textAlign={"Center"}
          >
            {/* <img
              src={aboutus}
              objectFit="cover"
              height={"15px"}
              width={"19px"}
              alt="aboutus"
              style={{ cursor: "pointer", marginRight: "2px" }}
              onClick={() => props.setToggleAbout(true)}
            /> */}
            {/* <Typography
              onClick={() => props.setToggleAbout(true)}
              variant="h6"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#000",
                lineHeight: "16px",
                textAlign: "right",
                paddingRight: 2,
                paddingTop: 2,
                paddingBottom: 2,
                border: "1px solid black",
                borderRadius: "5px",
                // textDecoration: "underline",
              }}
            >
              About Us
            </Typography> */}
            <CustomChip
              onClick={() => props.setToggleAbout(true)}
              label="About Us"
              variant="filled"
              sx={{
                border: "1px solid #CACACA",
                color: "#fff",
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
  );
};

export default Index;
