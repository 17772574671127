import React from "react";
import { Grid } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const CustomChip = styled(Chip)({
  [`&.${chipClasses.filled}`]: {
    backgroundColor: "black",
  },
});

const ChipsList = ({ categories, selected, handleChipSelect, menuList }) => {
  return (
    <Grid
      container
      paddingX={3}
      marginBlock={1}
      spacing={1}
      display="flex"
      flexWrap="nowrap"
      maxWidth="100%"
      sx={{
        overflowX: "auto",
        backgroundColor: "#fff",
        paddingBottom: 1,
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {categories?.result.map(
        (chip, i) =>
          menuList[chip.name]?.length > 0 && (
            <Grid item key={i}>
              <CustomChip
                label={chip.name}
                variant={selected === chip.name ? "filled" : "outlined"}
                sx={{
                  border: "1px solid #CACACA",
                  color: selected === chip.name ? "#fff" : "#000",
                }}
                onClick={() => handleChipSelect(chip.name)}
              />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default ChipsList;
