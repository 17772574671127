import React from "react";
import { Typography } from "@mui/material";

const ItemTitle = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "16.41px",
      }}
    >
      {title}
    </Typography>
  );
};

export default ItemTitle;
