import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccordionItem from "./AccordionItem";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Accordion = (props, ref) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ boxShadow: "none", mx: 2 }} ref={ref}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{ cursor: "pointer" }}
      >
        <Typography sx={{ fontWeight: 600 }}>
          {props?.label} ({props?.count})
        </Typography>
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ArrowDropDownIcon sx={{ fontSize: 26, color: "black" }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AccordionItem data={props?.data} />
      </Collapse>
    </Card>
  );
};

export default React.forwardRef(Accordion);
