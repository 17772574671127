import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function MenuDialog({
  open,
  setOpen,
  options,
  selected,
  handleChipSelect,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (name) => {
    handleChipSelect(name);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 435,
            borderRadius: "32px",
            paddingBlock: "8px",
          },
        }}
        open={open}
        hideBackdrop
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>{"Menu"}</DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <List dense>
            {options?.result.map((option) => {
              return (
                <ListItem
                  sx={{
                    textDecoration:
                      selected === option.name ? "underline" : "none",
                    fontWeight: selected === option.name ? 800 : 400,
                  }}
                  onClick={() => handleSelect(option.name)}
                  secondaryAction={<Typography>{option.count}</Typography>}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 400 }}>
                        {option.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
