import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://bdine.enfono.com/api_bdine_backend/api";

    axios(payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  menuListURL: "/core/menus/?limit=1000",
  subMenuListURL: "/core/menu/{id}/submenus/?limit=1000",
  categoriesListURL: "/core/categories/?limit=1000",
  ratingPostURL: "/core/menu/{id}/rating/",
  feedbackPostURL: "/core/feedback/",
  resturentInfoDetailsURL: "/core/restaurant/info/",
  imagesListURL: "/restaurant/profile_picture",
  actionHandler,
};
