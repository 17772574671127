import food from "../../assets/utils/food2.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "150px",
    borderRadius: "8px",
    zIndex: -1,
  },
  unavailable: {
    filter: "grayscale(100%)",
    objectFit: "cover",
    width: "100%",
    height: "150px",
    borderRadius: "8px",
    zIndex: -1,
  },
}));

const ItemImage = ({ image, status }) => {
  const classes = useStyles();
  return (
    <>
      {image ? (
        <img
          src={`${image}`}
          alt={
            <img
              src={food}
              style={{
                objectFit: "fill",
                width: "100%",
                height: "150px",
                borderRadius: "8px",
              }}
            />
          }
          style={{
            objectFit: "cover",
            width: "100%",
            height: "110px",
            borderRadius: "8px",
          }}
          className={status ? classes.image : classes.unavailable}
          loading="lazy"
        />
      ) : (
        <img
          src={food}
          alt={"Chicken Biriyani"}
          style={{
            objectFit: "fill",
            width: "100%",
            height: "150px",
            borderRadius: "8px",
          }}
          loading="lazy"
        />
      )}
    </>
  );
};

export default ItemImage;
