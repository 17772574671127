import api from "./api.js";

const fetchCategories = async () => {
  const response = await api.actionHandler({
    method: "get",
    url: api.categoriesListURL,
  });

  return response.data;
};

export default fetchCategories;
