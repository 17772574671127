import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ReactPlayer from "react-player/youtube";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoModal({ open, setOpen, url }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "95%" } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{ fontWeight: 600 }}>{"Video"}</DialogTitle> */}
        <DialogContent sx={{ padding: 1 }}>
          <ReactPlayer
            url={url}
            width={"100%"}
            height={200}
            controls
            playing={open}
            stopOnUnmount
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
