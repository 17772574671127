import * as React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  borderRadius: 4,
  padding: "10px 12px",
  border: "1px solid #ced4da",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: 16,
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function StyledTextArea({
  value,
  onChange,
  label,
  type,
  error,
  setErr,
  ...props
}) {
  return (
    <TextField
      minRows={8}
      placeholder=""
      style={{ width: "100%" }}
      value={value}
      label={label}
      error={error}
      helperText={error}
      onChange={onChange}
      onKeyDown={() => setErr("")}
      InputProps={{
        inputComponent: TextArea,
        inputProps: {
          style: {
            resize: "auto",
            height: "150px",
          },
        },
      }}
      {...props}
    />
  );
}
