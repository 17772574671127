import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const CustomSearchStyle = styled(TextField)({
  background: "#f5f7fa",
  paddingBlock: "12px",
  // border: "1px solid #EEEEEE",
  width: "98% !important",
  //on mobile
  "@media (max-width: 600px)": {
    marginInline: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#f5f7fa",
    },
    "&:hover fieldset": {
      borderColor: "#f5f7fa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f5f7fa",
    },
    "& .MuiInputBase-input": {
      color: "#666666",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontStyle: "normal",
      padding: "8px",
    },
  },
});

const CustomSearchInput = ({ label, onChange, value, ...rest }) => {
  return (
    <CustomSearchStyle
      id="outlined-basic"
      placeholder={label}
      onChange={onChange}
      value={value}
      {...rest}
      variant="outlined"
      sx={{ width: "100%" }}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
};

export default CustomSearchInput;
