import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";

const HeroCarousel = ({ resturentInfo }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Carousel
        style={{ cursor: "pointer" }}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        {resturentInfo?.result?.banner_images.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={item}
              style={{
                width: "95%",
                borderRadius: "10px",
                height: "220px",
                objectFit: "cover",
              }}
              loading="lazy"
              alt="hero"
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default HeroCarousel;
