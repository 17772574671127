import api from "./api.js";

const fetchMenuList = async () => {
  const response = await api.actionHandler({
    method: "get",
    url: api.menuListURL,
  });

  return response.data;
};

const fetchRecommended = async () => {
  const response = await api.actionHandler({
    method: "get",
    url: api.menuListURL + `&category=rec`,
  });

  return response.data;
};

export { fetchMenuList, fetchRecommended };
