import api from "./api.js";

const postRating = async ({ selectedID, rating }) => {
  const response = await api.actionHandler({
    method: "post",
    url: api.ratingPostURL.replace("{id}", selectedID),
    data: { rating },
  });

  return response.data;
};

export default postRating;
