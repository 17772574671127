import * as React from "react";
import {
  Grid,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  List,
  SwipeableDrawer,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import FeaturedRow from "./FeaturedRow";
import ItemDetailsImage from "./ItemDetailsImage";
import ItemTitle from "./ItemTitle";
import ItemRating from "./ItemRating";
import ItemPrice from "./ItemPrice";
import ItemDescription from "./ItemDescription";
import FeaturedImage from "./FeaturedImage";

import StyledRating from "./StyledRating";

import fetchSubMenuList from "../../api/fetchSubMenuList";
import { fetchMenuList } from "../../api/fetchMenuList";
import postRating from "../../api/postRating";

import { useQuery, useMutation } from "react-query";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ItemDetails({
  selectedID,
  state,
  toggleDrawer,
  selectedData,
  utilImages,
}) {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isLoading } = useQuery(
    ["subMenuList", selectedID],
    () => fetchSubMenuList(selectedID),
    {
      enabled: !!selectedID,
    }
  );

  const { refetch } = useQuery("menu-list", fetchMenuList);

  const {
    mutate,
    isSuccess: postRatingSuccess,
    isError: postRatingError,
    error: postRatingErrorMessage,
  } = useMutation(["postRating"], ({ id, rating }) => {
    return postRating({ selectedID: id, rating });
  });

  const handleRating = (event, id) => {
    mutate({ id, rating: event.target.value });
  };

  React.useEffect(() => {
    if (postRatingSuccess) {
      handleClick();
      toggleDrawer(false);
      refetch();
    }
    if (postRatingError) {
      handleClickError();
    }
  }, [postRatingSuccess, postRatingError]);

  const list = () => (
    <Box
      sx={{ overflow: "hidden" }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ marginBottom: 5 }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Rating added successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        sx={{ marginBottom: 5 }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {postRatingErrorMessage?.response?.data?.message}
        </Alert>
      </Snackbar>
      <Grid container minHeight={100} spacing={2} margin={3}>
        <Grid item xs={4}>
          <ItemDetailsImage image={selectedData.image} />
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <FeaturedRow
                  topper={selectedData.is_chart_topper}
                  image={
                    selectedData.food_type === "non-veg"
                      ? utilImages.nonveg
                      : utilImages.veg
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ItemTitle title={selectedData.name} />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <ItemRating
                  rating={selectedData.rating}
                  review_count={selectedData.rating_count}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ItemPrice price={selectedData.price} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <ItemDescription description={selectedData.description} />
          </Grid>
        </Grid>
      </Grid>
      <List sx={{ m: 2 }} dense>
        {!isLoading &&
          data?.data?.results?.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              secondaryAction={
                <ListItemText
                  edge="end"
                  primary={
                    <Typography
                      sx={{ fontWeight: 500, fontSize: "14px", color: "#000" }}
                    >
                      ₹{item.price}
                    </Typography>
                  }
                />
              }
            >
              <ListItemButton>
                <ListItemIcon>
                  <FeaturedImage
                    image={
                      item.food_type === "non-veg"
                        ? utilImages.nonveg
                        : utilImages.veg
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ fontWeight: 500, fontSize: "14px", color: "#000" }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        {isLoading && (
          <>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", mx: 3 }}
              width={"90%"}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", mx: 3 }}
              width={"90%"}
            />
          </>
        )}
      </List>
      <Grid container sx={{ m: 2 }}>
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <StyledRating handleRating={handleRating} selectedID={selectedID} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor={"bottom"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "white",
            borderRadius: "24px 24px 0px 0px",
          },
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
