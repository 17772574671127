import api from "./api.js";

const fetchResturentInfo = async () => {
  const response = await api.actionHandler({
    method: "get",
    url: api.resturentInfoDetailsURL,
  });

  return response.data;
};

export default fetchResturentInfo;
