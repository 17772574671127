import { Grid } from "@mui/material";
import FeaturedChip from "./FeaturedChip";
import FeaturedImage from "./FeaturedImage";

const FeaturedRow = ({ topper, image }) => {
  return (
    <>
      <Grid item>
        <FeaturedImage image={image} />
      </Grid>
      {topper && (
        <Grid item sx={{ display: "flex", alignItems: "start" }}>
          <FeaturedChip label="Chart-topper" />
        </Grid>
      )}
    </>
  );
};

export default FeaturedRow;
