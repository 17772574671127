import api from "./api.js";

const postFeedback = async ({ payload }) => {
  const response = await api.actionHandler({
    method: "post",
    url: api.feedbackPostURL,
    data: payload,
  });

  return response.data;
};

export default postFeedback;
