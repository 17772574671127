import * as React from "react";
import { Grid, Fab } from "@mui/material";
import { Box, styled } from "@mui/system";

import Header from "./components/header/Index";
import HeroCarousel from "./components/Carousel";
import MenuDialog from "./components/MenuModal";

import CustomSearchInput from "./components/controls/Index";

import AboutUs from "./components/AboutUs";
import ChipsList from "./components/ChipsList";
import TypeChipsList from "./components/TypeChipList";
import FoodAccordion from "./components/Accordion";
import Skeleton from "@mui/material/Skeleton";

import { fetchMenuList, fetchRecommended } from "./api/fetchMenuList";
import fetchCategories from "./api/fetchCategories";
import fetchResturentInfo from "./api/fetchResturentInfo";

import noresults from "./assets/utils/no-results.png";

import { useQuery } from "react-query";

import menu from "./assets/utils/menu.png";
import close from "./assets/utils/close.svg";

const FloatingFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: "#000101",
  color: "#fff",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgb(37,42,47)",
    color: "#fff",
  },
}));

function App() {
  const [toggleAbout, setToggleAbout] = React.useState(false);
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [selectedChip, setSelectedChip] = React.useState("Recommended");
  const [selectedType, setSelectedType] = React.useState("all");

  const [itemsLoading, setItemsLoading] = React.useState(false);

  const [Search, setSearch] = React.useState("");

  const ref = React.useRef(null);

  const [menuList, setMenuList] = React.useState([]);

  const { data, isLoading } = useQuery("menu-list", fetchMenuList, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const { data: categories, isLoading: categoryLoading } = useQuery(
    "categories-list",
    fetchCategories,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  const { data: recommendedData } = useQuery(
    [
      "recommended-list",
      selectedChip,
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
      },
    ],
    () => fetchRecommended()
  );

  const { data: resturentInfo } = useQuery(
    ["resturent-info"],
    () => fetchResturentInfo(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  React.useEffect(() => {
    console.log("data mapping");
    setItemsLoading(true);
    let stuff = {};
    if (data?.data?.results?.length > 0) {
      const formatedData = data.data.results.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});
      stuff = { ...formatedData };
    }
    if (recommendedData?.data?.results?.length > 0) {
      let recommendedObject = {};
      recommendedObject["Recommended"] = recommendedData.data.results;
      stuff = { ...recommendedObject, ...stuff };
    }

    if (selectedType == "all") {
      setMenuList(stuff);
    }

    if (selectedType !== "all") {
      let filtered = {};
      Object.keys(stuff).forEach((item) => {
        filtered[item] = stuff[item].filter(
          (food) => food.food_type === selectedType
        );
      });
      stuff = filtered;
      setMenuList(stuff);
    }
    setItemsLoading(false);
  }, [data, recommendedData, selectedType]);

  const clearSearch = () => {
    let stuff = {};
    if (data?.data?.results?.length > 0) {
      const formatedData = data.data.results.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});
      stuff = { ...formatedData };
    }
    if (recommendedData?.data?.results?.length > 0) {
      let recommendedObject = {};
      recommendedObject["Recommended"] = recommendedData.data.results;
      stuff = { ...recommendedObject, ...stuff };
    }
    setSelectedType("all");
    setMenuList(stuff);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setToggleAbout(open);
  };

  const handleChipSelect = (chip) => {
    setSelectedChip(chip);
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };

  React.useEffect(() => {
    if (selectedChip === "Recommended") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (ref.current) {
      var element = ref.current;
      var headerOffset = 270;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [selectedChip]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    console.log("clearing search");
    if (Search.length > 0) {
      let filtered = {};
      Object.keys(menuList).forEach((item) => {
        filtered[item] = menuList[item].filter((food) =>
          food.name.toLowerCase().includes(Search.toLowerCase())
        );
      });
      setMenuList(filtered);
    } else {
      clearSearch();
    }
  }, [Search]);

  let [sum, setSum] = React.useState(0);

  React.useEffect(() => {
    let sum = 0;
    Object.keys(menuList).forEach((item) => {
      sum += menuList[item].length;
    });
    setSum(sum);
  }, [menuList]);

  return (
    <React.Fragment>
      <Grid
        sx={{
          marginInline: 0,
          position: "sticky",
          top: -1,
          zIndex: 15,
        }}
      >
        <Header setToggleAbout={setToggleAbout} />
      </Grid>
      <Grid
        sx={{
          marginInline: 0,
          position: "sticky",
          top: 125,
          zIndex: 15,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomSearchInput
          label="Search Menu"
          value={Search}
          onChange={handleSearch}
        />
      </Grid>
      {resturentInfo?.result?.banner_images?.length > 0 && (
        <HeroCarousel resturentInfo={resturentInfo} />
      )}
      <Grid
        sx={{
          marginInline: 0,
          position: "sticky",
          top: 187,
          zIndex: 15,
        }}
      >
        {!categoryLoading && Object.keys(menuList).length !== 0 && (
          <TypeChipsList
            categories={[
              { name: "All", value: "all" },
              { name: "Veg", value: "veg" },
              { name: "Non-Veg", value: "non-veg" },
            ]}
            selectedType={selectedType}
            handleTypeSelect={handleTypeSelect}
          />
        )}
      </Grid>
      <Grid
        sx={{
          marginInline: 0,
          position: "sticky",
          top: 233,
          zIndex: 15,
        }}
      >
        {!categoryLoading && Object.keys(menuList).length !== 0 && (
          <ChipsList
            categories={categories}
            selected={selectedChip}
            handleChipSelect={handleChipSelect}
            menuList={menuList}
          />
        )}
      </Grid>

      {(categoryLoading ||
        isLoading ||
        itemsLoading ||
        Object.keys(menuList).length === 0) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <lottie-player
            src="https://lottie.host/8d8f9523-4f00-4b12-81ee-d1180cc6ce44/UIXi6ZK5LO.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "250px" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      )}

      {categoryLoading && (
        <Skeleton
          variant="rectangular"
          sx={{ my: 4, mx: 3 }}
          width={"90%"}
          height={60}
        />
      )}
      {!isLoading &&
        !itemsLoading &&
        Object.keys(menuList).map((item) => (
          <>
            {menuList[item].length > 0 && (
              <FoodAccordion
                ref={selectedChip === item ? ref : null}
                key={menuList[item].id}
                label={item}
                count={menuList[item].length}
                data={menuList[item]}
              />
            )}
          </>
        ))}
      {(isLoading || itemsLoading) && (
        <Skeleton
          variant="rectangular"
          sx={{ my: 4, mx: 3 }}
          width={"90%"}
          height={100}
        />
      )}
      {!isLoading && !itemsLoading && Object.keys(menuList).length === 0 && (
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_xy4tg1op.json"
          background="transparent"
          speed="0.5"
          style={{ width: "100%", height: "250px", marginTop: "15px" }}
          loop
          autoplay
        ></lottie-player>
      )}
      {Object.keys(menuList).length > 0 && sum === 0 && (
        <Box
          sx={{
            xs: { display: "flex", flexDirection: "column" },
            md: { display: "none" },
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          <img src={noresults} height="20%" width="30%" alt="empty" />
        </Box>
      )}
      <AboutUs
        state={toggleAbout}
        toggleDrawer={toggleDrawer}
        resturentInfo={resturentInfo}
      />
      <FloatingFab variant="extended" onClick={() => setToggleMenu(true)}>
        <img
          src={toggleMenu ? close : menu}
          height="30px"
          width="30px"
          alt="fab"
          style={{ marginRight: "10px" }}
        />
        {toggleMenu ? "Close" : "Menu"}
      </FloatingFab>
      <MenuDialog
        open={toggleMenu}
        setOpen={setToggleMenu}
        resturentInfo={resturentInfo}
        options={categories}
        selected={selectedChip}
        handleChipSelect={handleChipSelect}
      />
    </React.Fragment>
  );
}

export default App;
