import api from "./api.js";

const fetchSubMenuList = async (selectedID) => {
  const response = await api.actionHandler({
    method: "get",
    url: api.subMenuListURL.replace("{id}", selectedID),
  });

  return response.data;
};

export default fetchSubMenuList;
