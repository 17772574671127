import * as React from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function StyledInput({
  value,
  onChange,
  label,
  type,
  error,
  setErr,
  ...props
}) {
  return (
    <StyledTextField
      error={error}
      helperText={error}
      value={value}
      label={label}
      onKeyDown={() => setErr("")}
      fullWidth
      outlined
      onChange={onChange}
      type={type}
      {...props}
    />
  );
}
