import React from "react";
import { Rating, Box, Grid, Typography } from "@mui/material";

const ItemRating = ({ rating, review_count }) => {
  return (
    <>
      <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
        {(rating === null || rating) && (
          <Rating size="small" name="item-rating" value={rating} readOnly />
        )}{" "}
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ ml: 2 }}>
          <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
            {review_count} reviews
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default ItemRating;
