const FeaturedImage = ({ image }) => {
  return (
    <img
      src={`${image}`}
      alt={"Type"}
      style={{
        height: "90%",
      }}
      loading="lazy"
    />
  );
};

export default FeaturedImage;
